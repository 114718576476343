<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'Devices'}">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <div class="d-flex flex-column">

          <!-- String #ID, Software -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <CInput
              class="w-100 mr-2"
              label="String #ID"
              placeholder="String #ID"
              :lazy="false"
              :value.sync="$v.form.sid.$model"
              :isValid="checkIfValid('sid')"
              invalidFeedback="This is a required field!"
            />

            <CInput
              v-if="form.version"
              class="w-100 mr-2"
              label="App Version"
              placeholder="App Version"
              :lazy="false"
              :value="form.version"
              disabled
            />

            <div class="form-group w-100">
              <label class="d-block" style="font-weight: 500">Software:</label>
              <CSwitch
                :checked.sync="$v.form.software.$model"
                labelOn="YES"
                labelOff="NO"
                color="success"
                shape="pill"
                size="lg"
                :lazy="false" />
            </div>
          </div>

          <!-- IMEI Number, Model -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <CInput
              class="w-100 mr-2"
              label="IMEI"
              placeholder="IMEI"
              :lazy="false"
              :value.sync="$v.form.imei.$model"
            />

            <CInput
              class="w-100"
              label="Model"
              placeholder="Model"
              :lazy="false"
              :value.sync="$v.form.model.$model" 
            />
          </div>

          <!-- Serial Number, SIM Card Number -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <CInput
              class="w-100 mr-2"
              label="Serial Number"
              placeholder="Serial Number"
              :lazy="false"
              :value.sync="$v.form.serienr.$model"
            />

            <CInput
              class="w-100"
              label="SIM Card Number"
              placeholder="SIM Card Number"
              :lazy="false"
              :value.sync="$v.form.simkaartnr.$model"
            />
          </div>

          <!-- Notes -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <CTextarea
              class="w-100"
              rows="2"
              label="Notes"
              placeholder="Notes"
              :lazy="false"
              :value.sync="$v.form.notities.$model"
            />
          </div>

          <!-- Linked Restaurant -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <div class="form-group w-100 mr-2">
              <label class="d-block">Restaurant:</label>
              <v-select
                :reduce="c => c.value"
                placeholder="Please select.."
                :searchable="true"
                v-model="$v.form.restaurant.$model"
                :options="restaurants"
              />
            </div>

            <div v-if="form.restaurant" role="group" class="w-100 form-group">
              <label class="d-block">&nbsp;</label>
              <CButton
                color="danger"
                size="sm"
                shape="pill"
                @click="() => form.restaurant = null"
              >
                <CIcon name="cil-x" class="mb-1"/>&nbsp;Unlink
              </CButton>
            </div>
          </div>
        </div>
      </CForm>
    </CCardBody>

    <CCardFooter>
      <CButton
        color="primary"
        :disabled="submitted"
        @click="submit()"
      >
        Submit
      </CButton>
    </CCardFooter>

    <CElementCover :opacity="0.4" v-show="submitted"/>

  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  name: 'DeviceItem',

  data() {
    return {
      form: {},
      isEdit: false,
      submitted: false,

      itemName: '',
      restaurants: [],

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    }
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    itemId() { return this.$route.params.id }
  },

  watch: {
    'form.software': function(newVal, oldVal) {
      if (this.isEdit === false) {
        this.$store.state.deviceSoftware = newVal;
      }
    },
  },

  mixins: [validationMixin],

  validations: {
    form: {
      sid: { required },
      serienr: { },
      simkaartnr: { },
      notities: { },
      software: { },
      imei: { },
      model: { },
      restaurant: { }
    }
  },

  created () {
    if(this.itemId) {
      this.form = this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.form = this.getForm()
  },

  mounted() {
    this.getRestaurantNames();
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    async submit () {
      if (!this.isValid) {
        this.validate();
        return;
      }

      if (this.isEdit) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async store() {
      this.submitted = true;

      await this.$axios.post(this.$backend.DEVICE.STORE, this.form)
        .then((response)=>{
          this.successAlertMessage = {itemName: response.data.sid, message: 'device added.'}
          this.dismissSuccessAlert = this.dismissSecs
          this.reset();
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
          this.submitted = false
        })
    },

    async update() {
      this.submitted = true;
      await this.$axios.put(this.$backend.DEVICE.UPDATE.replace("{id}", this.itemId), this.form)
        .then((response)=>{
          this.itemName = response.data.sid
          this.successAlertMessage = {itemName: this.itemName, message: 'device updated.'}
          this.dismissSuccessAlert = this.dismissSecs
          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
        .finally(() => {
          this.submitted = false
        });
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getForm()
      this.submitted = false
      this.showErrorAlert = false
      this.$v.$reset()
    },

    getForm (id = null) {
      var form = {
        sid: null,
        serienr: null,
        simkaartnr: null,
        notities: null,
        software: this.$store.state.deviceSoftware,
        version: null,
        imei: null,
        model: null,
        restaurant: null
      }

      if(id) {
        this.$axios
          .get(
            this.$backend.DEVICE.SHOW.replace("{id}", id)
          )
          .then((res) => {
            form.sid =  res.data.sid;
            form.serienr =  res.data.serienr;
            form.simkaartnr =  res.data.simkaartnr;
            form.sid =  res.data.sid;
            form.notities =  res.data.notities;
            form.software =  res.data.software;
            form.version =  res.data.version;
            form.imei =  res.data.imei;
            form.model =  res.data.model;

            form.restaurant =  res.data.restaurants.length > 0 ? res.data.restaurants[0].id : null;

            this.itemName = res.data.sid;
          })
          .catch((error) => {
            this.$router.push({ name: 'Devices' })
          })
      }
      return form;
    },

    getRestaurantNames() {
      this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.restaurants = response.data.sort((a,b) => b.value - a.value);
        })
        .catch(() => { this.restaurants = [] })
    },
  }
}
</script>
